<template>
  <div>
    <div class="pageContent">
      <noNavHeader title="公告" />
      <div class="notice">
        <div class="subPageTitle">
          <div class="subPageTitleText">
            <i
              class="el-icon-arrow-left"
              style="font-size: 18px;cursor:pointer;"
              @click="goBack"
            ></i>
            <p>公告</p>
          </div>
        </div>
        <div class="newsList">
          <div
            class="newsItem"
            v-for="item in noticeDatas"
            :key="item.id"
            @click="goNewsDetails(item.id)"
          >
            <div class="newsContent">
              <el-row>
                <el-col :span="20">
                  <p class="newsTitle">{{ item.title }}</p>
                </el-col>
                <el-col :span="4">
                  <p class="time">{{ item.createTime | DateFormate }}</p>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
        <el-row class="pagination" v-if="noticeDatas.length != 0">
          <el-col :span="24">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="current"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalPage"
            ></el-pagination>
          </el-col>
        </el-row>
        <div v-if="noticeDatas.length == 0" class="nullData">暂无数据</div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import noNavHeader from "../base/header/noNavHeader";
import Footer from "@/components/base/footer/footerZYQ";
export default {
  data() {
    return {
      current: 1,
      pageSize: 10,
      totalPage: null,
      noticeDatas: []
    };
  },
  components: {
    noNavHeader,
    Footer
  },
  mounted() {
    this.getNoticeListDatas();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getNoticeListDatas();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getNoticeListDatas();
    },
    getNoticeListDatas() {
      protocolFwd.param_getContentTitleList.param.page = this.current - 1;
      protocolFwd.param_getContentTitleList.param.size = this.pageSize;
      protocolFwd.param_getContentTitleList.param.columnId = window.c.notice;
      http.getRes(protocolFwd.param_getContentTitleList).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.noticeDatas = value.content;
          this.totalPage = value.totalElements;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    goNewsDetails(id) {
      this.$router.push({
        name: "noticeDetails",
        params: {
          id: id
        }
      });
    }
  }
};
</script>
<style scoped>
.notice {
  padding: 15px;
  width: 1200px;
  /* width: 80%;
  min-width: 1300px; */
  min-height: 1000px;
  margin: 0 auto;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
}
.subPageTitle .subPageTitleText {
  line-height: 40px;
}
.subPageTitle .subPageTitleText p {
  font-size: 16px;
}
.newsList .newsItem {
  line-height: 35px;
  border-bottom: 1px dashed #f2f2f2;
  cursor: pointer;
}
.newsList .newsItem .newsTitle {
  font-size: 14px;
}
.newsList .newsItem .time {
  color: #9999a2;
}
.pagination {
  margin-top: 15px;
}
.nullData {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
